const languages = [
  { locale: 'en' },
  { locale: 'fr' },
  { locale: 'es' },
  { locale: 'ca' },
  { locale: 'it' },
  { locale: 'de' },
  { locale: 'nl' },
  { locale: 'pt-BR' },
  { locale: 'zh-CN' },
  { locale: 'ko' },
  { locale: 'ar' },
  { locale: 'ru' },
]

export default languages
