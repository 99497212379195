import React from 'react'
import { graphql } from 'gatsby'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet'

import ArticleList from 'components/ArticleList'
import Hero from 'components/Hero'
import Layout from 'components/Layout'

import renderContent from 'utils/componentRenderer'
import {
  filterRepeatedContent,
  groupContentTypes,
  NON_REPEATED_CONTENT_TYPES_T02,
} from 'utils/contentUtils'
import { Spacing50 } from 'styles/sharedStyle'

import languages from '../constants/languages'

const SectionStoryLandingPage = (props) => {
  const {
    data,
    location,
    pageContext: { pageLevel },
  } = props
  const {
    articles,
    articlesT17,
    collectionArticles,
    collectionArticlesT17,
    articleLabels,
    page,
    page: { hero, content, articlesToShare, jsonLdSchema, village },
  } = data
  const labels = articleLabels.edges[0].node

  const manuallyCuratedArticles =
    articlesToShare && articlesToShare.length ? articlesToShare : []

  const manuallyCuratedArticlesIds = new Set(
    manuallyCuratedArticles.map(({ id }) => id)
  )

  // make a better article list
  const allArticlesT08 = (pageLevel === 'village'
    ? articles
    : collectionArticles
  ).edges.reduce((arr, article) => {
    arr.push({ ...article.node })
    return arr
  }, [])

  const allArticlesT17 = (pageLevel === 'village'
    ? articlesT17
    : collectionArticlesT17
  ).edges.reduce((arr, article) => {
    arr.push({ ...article.node })
    return arr
  }, [])

  /* DX-2275 sort articles by date here as GraphQL uses different
    key for publish date values for both templates are using */
  const allArticles = [...allArticlesT08, ...allArticlesT17]
    .filter((item) => item.publishDate)
    .sort((a, b) => {
      const date1 = new Date(b.publishDate)
      const date2 = new Date(a.publishDate)
      return date1.getTime() - date2.getTime()
    })

  const extractLocales = (property) => (obj) => obj[property]

  const storyArticles = allArticles.filter((article) => {
    if (manuallyCuratedArticlesIds.has(article.id)) {
      return false
    }

    const translations = article.translations || languages
    const locales = translations.map(extractLocales('locale'))

    return (
      article.pageType &&
      article.pageType.name === 'stories' &&
      locales.includes(page.locale)
    )
  })

  const storyArticlesIncludingManuallyAdded = [
    ...manuallyCuratedArticles,
    ...storyArticles,
  ]

  const media = hero && hero.media && hero.media[0]
  const hasHeroImage =
    (media && media.portrait && media.landscape) ||
    (media &&
      media.videoPlaceholder &&
      media.videoPlaceholder.landscape &&
      media.videoPlaceholder.portrait)
  const heroImage = hasHeroImage
    ? {
        portrait: media.portrait || media.videoPlaceholder.portrait,
        landscape: media.landscape || media.videoPlaceholder.landscape,
        altText:
          media.altText ||
          (media.videoPlaceholder ? media.videoPlaceholder.altText : ''),
      }
    : {}
  const hasHeroVideo = media && media.videoPortrait && media.videoLandscape
  const heroVideo = hasHeroVideo
    ? {
        portrait: media.videoPortrait,
        landscape: media.videoLandscape,
        opacity: media.opacity,
      }
    : {}
  const heroProps = hero
    ? {
        eyebrow: hero.eyebrow,
        content: hero.headline,
        image: heroImage,
        video: heroVideo,
        logo: page.brandLogo,
        villageSlug:
          pageLevel === 'collection'
            ? 'The Bicester Collection'
            : village?.name,
      }
    : null

  const contentToRender =
    !!content &&
    groupContentTypes(
      filterRepeatedContent(content, NON_REPEATED_CONTENT_TYPES_T02)
    )

  return (
    <Layout {...props}>
      <Helmet>
        {jsonLdSchema && jsonLdSchema.jsonLdSchema ? (
          <script type="application/ld+json">
            {jsonLdSchema.jsonLdSchema}
          </script>
        ) : null}
      </Helmet>

      {heroProps && <Hero {...heroProps} />}

      <Spacing50 />

      {renderContent(contentToRender, props)}

      {storyArticlesIncludingManuallyAdded.length > 0 && (
        <ArticleList
          articles={storyArticlesIncludingManuallyAdded.map(
            ({
              category,
              publishDate,
              heading,
              slug,
              hero: {
                headline: heroHeading,
                media: heroMedia,
                eyebrow: heroEyebrow,
              },
              pageType,
              // eslint-disable-next-line no-shadow
              village,
              locale,
            }) => ({
              category: category || heroEyebrow,
              image:
                heroMedia &&
                heroMedia[0] &&
                (heroMedia[0].videoPlaceholder || heroMedia[0]),
              publishDate,
              heading: heading || heroHeading,
              slug,
              pageType,
              village,
              locale,
              villageName:
                pageLevel === 'collection'
                  ? 'The Bicester Collection'
                  : page?.village?.name,
            })
          )}
          eyebrow={labels.filterEyebrow}
          header={labels.filterHeader}
          loadMore={labels.loadMoreStoriesCta}
          pathname={location.pathname}
        />
      )}
    </Layout>
  )
}

SectionStoryLandingPage.propTypes = {
  data: PropTypes.oneOfType([PropTypes.object]).isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }).isRequired,
  pageContext: PropTypes.shape({
    pageLevel: PropTypes.string.isRequired,
  }).isRequired,
}

export const sectionLandingPageQuery = graphql`
  query(
    $id: String!
    $openingHoursId: String
    #    $dateNow: Date!
    $dateStart: Date!
    $dateEnd: Date!
    $nodeLocale: String!
    $collectionId: String
    $pageTypeSlug: String
    $villageCode: String
    $villageSlug: String!
    $needContactIcons: Boolean = false
  ) {
    page: contentfulPageTemplateSectionLandingT02(id: { eq: $id }) {
      jsonLdSchema {
        jsonLdSchema
      }
      locale: node_locale
      village {
        name
        villageSlug: slug
        currency
        home: page_template_home_t01 {
          ...headerFooterVillage
          locale: node_locale
        }
        villagePlaceholderImage: image {
          __typename
          ... on Node {
            ... on ContentfulEntityImageEnt02 {
              ...ResponsiveImageQuery
            }
          }
        }
        openingHours {
          ...villageOpeningHours
        }
        labels: label_brand_details_lab01 {
          readMoreLabel
          readLessLabel
          contactDetailsHeader
          ...virtualShoppingIconsAndLabelsQuery
          onlineLabel
          viewOnMapLabel
        }
        defaultLocale
        openingStatusLabel
      }
      hero {
        title
        headline
        eyebrow
        ...heroMediaQuery
      }
      content {
        __typename
        ... on Node {
          ...multipleComponentsT02
        }
      }
      articlesToShare {
        __typename
        ... on Node {
          ... on ContentfulPageTemplateEditorialT08 {
            ...articleQuery
          }
          ... on ContentfulPageTemplateLifestyleT17 {
            ...articleQueryT17
          }
        }
      }
      pageTitle
      pageDescription
      hideFromSearchEngine
    }
    openingHoursExceptions: allContentfulCompOpeningHoursExceptionOph04(
      filter: {
        date: { lte: $dateEnd, gte: $dateStart }
        comp_opening_hours_village_oph01: {
          elemMatch: { id: { eq: $openingHoursId } }
        }
      }
    ) {
      exceptions: edges {
        node {
          date(formatString: "DD-MM-YYYY")
          openingTime
          closingTime
          closedFlag
          label
        }
      }
    }
    openingHoursLabels: allContentfulLabelOpeningHoursLab04(
      filter: { node_locale: { eq: $nodeLocale } }
    ) {
      edges {
        node {
          ...contentfulOpeningHoursLabels
        }
      }
    }
    articles: allContentfulPageTemplateEditorialT08(
      filter: {
        village: { code: { eq: $villageCode } }
        node_locale: { eq: $nodeLocale }
      }
      sort: { fields: [published], order: DESC }
    ) {
      edges {
        node {
          ...articleQuery
        }
      }
    }
    articlesT17: allContentfulPageTemplateLifestyleT17(
      filter: {
        village: { code: { eq: $villageCode } }
        node_locale: { eq: $nodeLocale }
      }
      sort: { fields: [publishDate], order: DESC }
    ) {
      edges {
        node {
          ...articleQueryT17
        }
      }
    }
    collectionArticles: allContentfulPageTemplateEditorialT08(
      filter: {
        village: {
          code: { nin: ["BV", "FV", "LR", "LVV", "WV", "IV", "LR", "KV", "MV"] }
        }
        node_locale: { eq: $nodeLocale }
      }
      sort: { fields: [published], order: DESC }
    ) {
      edges {
        node {
          ...articleQuery
        }
      }
    }
    collectionArticlesT17: allContentfulPageTemplateLifestyleT17(
      filter: {
        village: {
          code: { nin: ["BV", "FV", "LR", "LVV", "WV", "IV", "LR", "KV", "MV"] }
        }
        node_locale: { eq: $nodeLocale }
      }
      sort: { fields: [publishDate], order: DESC }
    ) {
      edges {
        node {
          ...articleQueryT17
        }
      }
    }
    articleLabels: allContentfulLabelStoryArticleLab06(
      filter: { node_locale: { eq: $nodeLocale } }
    ) {
      edges {
        node {
          filterEyebrow
          filterHeader
          filterDropdownPrompt
          loadMoreStoriesCta
        }
      }
    }
    collectionPage: contentfulPageTemplateHomeT01(id: { eq: $collectionId }) {
      header {
        ...header
      }
      footer {
        ...footer
      }
    }
    eventLabels: allContentfulLabelVillageLab09(
      filter: { node_locale: { eq: $nodeLocale } }
    ) {
      edges {
        node {
          eventMoreInfoLabel
        }
      }
    }
    villages: allContentfulEntityVillageEnt01(
      filter: { node_locale: { eq: $nodeLocale } }
    ) {
      nodes {
        name
        slug
        node_locale
        city
        openingStatusLabel
        cardImage {
          portrait {
            file {
              url
            }
          }
        }
      }
    }
    #    featuredOffers: allContentfulEntityOfferEnt05(
    #      filter: {
    #        node_locale: { eq: $nodeLocale }
    #        offerRunFinishDate: { gte: $dateNow }
    #      }
    #      sort: { fields: offerRunFinishDate }
    #    ) {
    #      nodes {
    #        id: contentful_id
    #        location {
    #          locationId: contentful_id
    #        }
    #        eyebrow
    #        headline
    #        description {
    #          description
    #        }
    #        offerDetailUrl
    #        offerExpirationLabel
    #        offerRunStartDate(formatString: "D MMMM")
    #        offerRunFinishDate(formatString: "D MMMM YYYY")
    #        offerImage {
    #          ...heroMediaQuery
    #        }
    #      }
    #    }
    labels: allContentfulLabelVillageLab09(
      filter: { node_locale: { eq: $nodeLocale } }
    ) {
      edges {
        node {
          visitBoutiqueLabel
        }
      }
    }
    memDaysOnboarding: contentfulCompMemDayOnb01(
      node_locale: { eq: $nodeLocale }
      village: { slug: { eq: $villageSlug } }
      pageTypes: { elemMatch: { slug: { eq: $pageTypeSlug } } }
    ) {
      ...memDaysOnboarding
    }
    allMemorableDays: allContentfulPageTemplateMemorableDaysT14(
      filter: {
        node_locale: { eq: $nodeLocale }
        village: { slug: { eq: $villageSlug } }
      }
    ) {
      edges {
        node {
          ...memDaysSlugs
        }
      }
    }
  }
`

export default SectionStoryLandingPage
